import * as i0 from '@angular/core';
import { inject, TemplateRef, Component, ChangeDetectionStrategy, ViewChild, ContentChild, Directive, NgModule } from '@angular/core';
import { tuiIsNativeFocused } from '@taiga-ui/cdk/utils/focus';
import { tuiAsDataListHost, TuiDataListDirective } from '@taiga-ui/core/components/data-list';
import * as i1$1 from '@taiga-ui/core/directives/dropdown';
import { TuiDropdownOpen, TuiDropdown } from '@taiga-ui/core/directives/dropdown';
import { AbstractTuiControl, tuiAsControl, AbstractTuiTextfieldHost } from '@taiga-ui/legacy/classes';
import * as i1 from '@taiga-ui/legacy/components/primitive-textfield';
import { TuiPrimitiveTextfieldComponent, TuiPrimitiveTextfieldModule, TuiTextfieldComponent } from '@taiga-ui/legacy/components/primitive-textfield';
import { TUI_TEXTFIELD_SIZE, TUI_VALUE_ACCESSOR_PROVIDER } from '@taiga-ui/legacy/directives';
import { tuiAsFocusableItemAccessor, tuiAsTextfieldHost } from '@taiga-ui/legacy/tokens';
import { FIXED_DROPDOWN_CONTROLLER_PROVIDER } from '@taiga-ui/legacy/utils';
import { CommonModule } from '@angular/common';
const _c0 = ["*", [["input"]]];
const _c1 = ["*", "input"];
class TuiInputComponent extends AbstractTuiControl {
  constructor() {
    super(...arguments);
    this.textfieldSize = inject(TUI_TEXTFIELD_SIZE);
    this.open = false;
  }
  get size() {
    return this.textfieldSize.size;
  }
  get nativeFocusableElement() {
    return this.computedDisabled || !this.textfield ? null : this.textfield.nativeFocusableElement;
  }
  get focused() {
    return tuiIsNativeFocused(this.nativeFocusableElement) || !!this.dropdown?.tuiDropdownOpen;
  }
  handleOption(item) {
    this.setNativeValue(String(item));
    this.focusInput();
    this.value = String(item);
    this.open = false;
  }
  onValueChange(value) {
    this.value = value;
    this.open = true;
  }
  get canOpen() {
    return this.interactive && !!this.datalist;
  }
  onActiveZone(active) {
    this.updateFocused(active);
  }
  getFallbackValue() {
    return '';
  }
  focusInput(preventScroll = false) {
    if (this.nativeFocusableElement) {
      this.nativeFocusableElement.focus({
        preventScroll
      });
    }
  }
  setNativeValue(value) {
    if (this.nativeFocusableElement) {
      this.nativeFocusableElement.value = value;
    }
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTuiInputComponent_BaseFactory;
      return function TuiInputComponent_Factory(__ngFactoryType__) {
        return (ɵTuiInputComponent_BaseFactory || (ɵTuiInputComponent_BaseFactory = i0.ɵɵgetInheritedFactory(TuiInputComponent)))(__ngFactoryType__ || TuiInputComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TuiInputComponent,
      selectors: [["tui-input"]],
      contentQueries: function TuiInputComponent_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, TuiDataListDirective, 5, TemplateRef);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.datalist = _t.first);
        }
      },
      viewQuery: function TuiInputComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(TuiDropdownOpen, 5);
          i0.ɵɵviewQuery(TuiPrimitiveTextfieldComponent, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.dropdown = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.textfield = _t.first);
        }
      },
      hostAttrs: ["ngSkipHydration", "true"],
      hostVars: 1,
      hostBindings: function TuiInputComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵattribute("data-size", ctx.size);
        }
      },
      features: [i0.ɵɵProvidersFeature([TUI_VALUE_ACCESSOR_PROVIDER, tuiAsFocusableItemAccessor(TuiInputComponent), tuiAsDataListHost(TuiInputComponent), tuiAsControl(TuiInputComponent)], [FIXED_DROPDOWN_CONTROLLER_PROVIDER]), i0.ɵɵInheritDefinitionFeature],
      ngContentSelectors: _c1,
      decls: 4,
      vars: 11,
      consts: [["tuiDropdownOpenMonitor", "", 1, "t-hosted", 3, "tuiDropdownOpenChange", "tuiActiveZoneChange", "tuiDropdown", "tuiDropdownEnabled", "tuiDropdownOpen"], ["automation-id", "tui-input__textfield", 1, "t-textfield", 3, "valueChange", "disabled", "focusable", "invalid", "nativeId", "pseudoFocus", "pseudoHover", "readOnly", "value"]],
      template: function TuiInputComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c0);
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵtwoWayListener("tuiDropdownOpenChange", function TuiInputComponent_Template_div_tuiDropdownOpenChange_0_listener($event) {
            i0.ɵɵtwoWayBindingSet(ctx.open, $event) || (ctx.open = $event);
            return $event;
          });
          i0.ɵɵlistener("tuiActiveZoneChange", function TuiInputComponent_Template_div_tuiActiveZoneChange_0_listener($event) {
            return ctx.onActiveZone($event);
          });
          i0.ɵɵelementStart(1, "tui-primitive-textfield", 1);
          i0.ɵɵlistener("valueChange", function TuiInputComponent_Template_tui_primitive_textfield_valueChange_1_listener($event) {
            return ctx.onValueChange($event);
          });
          i0.ɵɵprojection(2);
          i0.ɵɵprojection(3, 1, ["ngProjectAs", "input", 5, ["input"]]);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵproperty("tuiDropdown", ctx.datalist || "")("tuiDropdownEnabled", ctx.canOpen);
          i0.ɵɵtwoWayProperty("tuiDropdownOpen", ctx.open);
          i0.ɵɵadvance();
          i0.ɵɵproperty("disabled", ctx.computedDisabled)("focusable", ctx.computedFocusable)("invalid", ctx.computedInvalid)("nativeId", ctx.nativeId)("pseudoFocus", ctx.computedFocused)("pseudoHover", ctx.pseudoHover)("readOnly", ctx.readOnly)("value", ctx.value);
        }
      },
      dependencies: [i1.TuiPrimitiveTextfieldComponent, i1.TuiPrimitiveTextfieldDirective, i1$1.TuiDropdownDirective, i1$1.TuiDropdownOpen],
      styles: ["[_nghost-%COMP%]{display:block;border-radius:var(--tui-radius-m);text-align:start}._disabled[_nghost-%COMP%]{pointer-events:none}.t-hosted[_ngcontent-%COMP%]{display:block;border-radius:inherit}.t-textfield[_ngcontent-%COMP%]{border-radius:inherit;text-align:inherit}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiInputComponent, [{
    type: Component,
    args: [{
      standalone: false,
      selector: 'tui-input',
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [TUI_VALUE_ACCESSOR_PROVIDER, tuiAsFocusableItemAccessor(TuiInputComponent), tuiAsDataListHost(TuiInputComponent), tuiAsControl(TuiInputComponent)],
      viewProviders: [FIXED_DROPDOWN_CONTROLLER_PROVIDER],
      host: {
        ngSkipHydration: 'true',
        '[attr.data-size]': 'size'
      },
      template: "<div\n    tuiDropdownOpenMonitor\n    class=\"t-hosted\"\n    [tuiDropdown]=\"datalist || ''\"\n    [tuiDropdownEnabled]=\"canOpen\"\n    [(tuiDropdownOpen)]=\"open\"\n    (tuiActiveZoneChange)=\"onActiveZone($event)\"\n>\n    <tui-primitive-textfield\n        automation-id=\"tui-input__textfield\"\n        class=\"t-textfield\"\n        [disabled]=\"computedDisabled\"\n        [focusable]=\"computedFocusable\"\n        [invalid]=\"computedInvalid\"\n        [nativeId]=\"nativeId\"\n        [pseudoFocus]=\"computedFocused\"\n        [pseudoHover]=\"pseudoHover\"\n        [readOnly]=\"readOnly\"\n        [value]=\"value\"\n        (valueChange)=\"onValueChange($event)\"\n    >\n        <ng-content />\n        <ng-content\n            ngProjectAs=\"input\"\n            select=\"input\"\n        />\n    </tui-primitive-textfield>\n</div>\n",
      styles: [":host{display:block;border-radius:var(--tui-radius-m);text-align:start}:host._disabled{pointer-events:none}.t-hosted{display:block;border-radius:inherit}.t-textfield{border-radius:inherit;text-align:inherit}\n"]
    }]
  }], null, {
    dropdown: [{
      type: ViewChild,
      args: [TuiDropdownOpen]
    }],
    textfield: [{
      type: ViewChild,
      args: [TuiPrimitiveTextfieldComponent]
    }],
    datalist: [{
      type: ContentChild,
      args: [TuiDataListDirective, {
        read: TemplateRef
      }]
    }]
  });
})();
class TuiInputDirective extends AbstractTuiTextfieldHost {
  onValueChange(value) {
    this.host.onValueChange(value);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTuiInputDirective_BaseFactory;
      return function TuiInputDirective_Factory(__ngFactoryType__) {
        return (ɵTuiInputDirective_BaseFactory || (ɵTuiInputDirective_BaseFactory = i0.ɵɵgetInheritedFactory(TuiInputDirective)))(__ngFactoryType__ || TuiInputDirective);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiInputDirective,
      selectors: [["tui-input"]],
      features: [i0.ɵɵProvidersFeature([tuiAsTextfieldHost(TuiInputDirective)]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiInputDirective, [{
    type: Directive,
    args: [{
      standalone: false,
      selector: 'tui-input',
      providers: [tuiAsTextfieldHost(TuiInputDirective)]
    }]
  }], null, null);
})();
class TuiInputModule {
  static {
    this.ɵfac = function TuiInputModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiInputModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: TuiInputModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, TuiPrimitiveTextfieldModule, i1$1.TuiDropdownComponent]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiInputModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, TuiPrimitiveTextfieldModule, ...TuiDropdown],
      declarations: [TuiInputComponent, TuiInputDirective],
      exports: [TuiInputComponent, TuiInputDirective, TuiTextfieldComponent, ...TuiDropdown]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiInputComponent, TuiInputDirective, TuiInputModule };
