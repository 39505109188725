import * as i0 from '@angular/core';
import { inject, Component, ChangeDetectionStrategy, ViewChild, Input, NgModule } from '@angular/core';
import { TUI_IS_MOBILE } from '@taiga-ui/cdk/tokens';
import * as i1$1 from '@taiga-ui/core/directives/hint';
import { TuiHintOptionsDirective, TuiHintHover, TuiHint } from '@taiga-ui/core/directives/hint';
import * as i1 from '@taiga-ui/core/components/icon';
import { TuiIcon } from '@taiga-ui/core/components/icon';
import * as i2 from '@taiga-ui/polymorpheus';
import { PolymorpheusOutlet } from '@taiga-ui/polymorpheus';
import * as i3 from '@taiga-ui/core/directives/appearance';
import { TuiAppearance } from '@taiga-ui/core/directives/appearance';
import * as i5 from '@angular/common';
import { CommonModule } from '@angular/common';

/**
 * @deprecated: drop in v5.0 use {@link TuiTooltip}
 * https://taiga-ui.dev/components/tooltip
 */
function TuiTooltipComponent_tui_icon_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "tui-icon", 3);
  }
  if (rf & 2) {
    const src_r1 = ctx.polymorpheusOutlet;
    i0.ɵɵproperty("icon", src_r1);
  }
}
class TuiTooltipComponent extends TuiHintOptionsDirective {
  constructor() {
    super(...arguments);
    this.isMobile = inject(TUI_IS_MOBILE);
    this.describeId = '';
  }
  get computedAppearance() {
    return this.appearance || '';
  }
  stopOnMobile(event) {
    if (this.isMobile) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.driver$?.toggle();
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTuiTooltipComponent_BaseFactory;
      return function TuiTooltipComponent_Factory(__ngFactoryType__) {
        return (ɵTuiTooltipComponent_BaseFactory || (ɵTuiTooltipComponent_BaseFactory = i0.ɵɵgetInheritedFactory(TuiTooltipComponent)))(__ngFactoryType__ || TuiTooltipComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TuiTooltipComponent,
      selectors: [["tui-tooltip"]],
      viewQuery: function TuiTooltipComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(TuiHintHover, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.driver$ = _t.first);
        }
      },
      hostAttrs: ["ngSkipHydration", "true"],
      hostVars: 1,
      hostBindings: function TuiTooltipComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("mousedown", function TuiTooltipComponent_mousedown_HostBindingHandler($event) {
            return ctx.stopOnMobile($event);
          });
        }
        if (rf & 2) {
          i0.ɵɵattribute("data-appearance", ctx.computedAppearance);
        }
      },
      inputs: {
        content: "content",
        direction: "direction",
        appearance: "appearance",
        showDelay: "showDelay",
        hideDelay: "hideDelay",
        describeId: "describeId",
        context: "context"
      },
      features: [i0.ɵɵInheritDefinitionFeature],
      decls: 4,
      vars: 11,
      consts: [["driver", "tuiHintHover"], ["automation-id", "tui-tooltip__icon", "tuiAppearance", "icon", 1, "t-tooltip-icon", 3, "tuiAppearanceState", "tuiHint", "tuiHintAppearance", "tuiHintContext", "tuiHintDescribe", "tuiHintDirection", "tuiHintHideDelay", "tuiHintShowDelay"], ["class", "t-icon", 3, "icon", 4, "polymorpheusOutlet"], [1, "t-icon", 3, "icon"]],
      template: function TuiTooltipComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "span", 1, 0);
          i0.ɵɵpipe(2, "async");
          i0.ɵɵtemplate(3, TuiTooltipComponent_tui_icon_3_Template, 1, 1, "tui-icon", 2);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          const driver_r2 = i0.ɵɵreference(1);
          i0.ɵɵproperty("tuiAppearanceState", i0.ɵɵpipeBind1(2, 9, driver_r2) ? "hover" : null)("tuiHint", ctx.content)("tuiHintAppearance", ctx.computedAppearance)("tuiHintContext", ctx.context)("tuiHintDescribe", ctx.describeId)("tuiHintDirection", ctx.direction)("tuiHintHideDelay", ctx.hideDelay)("tuiHintShowDelay", ctx.showDelay);
          i0.ɵɵadvance(3);
          i0.ɵɵproperty("polymorpheusOutlet", ctx.icon);
        }
      },
      dependencies: [i1.TuiIcon, i2.PolymorpheusOutlet, i3.TuiAppearance, i1$1.TuiHintDirective, i1$1.TuiHintDescribe, i5.AsyncPipe],
      styles: ["[_nghost-%COMP%]{transition-property:color;transition-duration:var(--tui-duration, .3s);transition-timing-function:ease-in-out;position:relative;display:inline-block;inline-size:1.5rem;block-size:1.5rem;vertical-align:middle;font-size:0;line-height:0;pointer-events:auto;cursor:pointer;color:var(--tui-text-primary)}[data-appearance=error][_nghost-%COMP%]{color:var(--tui-text-negative)}.t-tooltip-icon[_ngcontent-%COMP%]{display:inline-block;inline-size:100%;block-size:100%}.t-icon[_ngcontent-%COMP%]{border:.25rem solid transparent}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiTooltipComponent, [{
    type: Component,
    args: [{
      standalone: false,
      selector: 'tui-tooltip',
      changeDetection: ChangeDetectionStrategy.OnPush,
      inputs: ['content', 'direction', 'appearance', 'showDelay', 'hideDelay'],
      host: {
        ngSkipHydration: 'true',
        '[attr.data-appearance]': 'computedAppearance',
        '(mousedown)': 'stopOnMobile($event)'
      },
      template: "<span\n    #driver=\"tuiHintHover\"\n    automation-id=\"tui-tooltip__icon\"\n    tuiAppearance=\"icon\"\n    class=\"t-tooltip-icon\"\n    [tuiAppearanceState]=\"(driver | async) ? 'hover' : null\"\n    [tuiHint]=\"content\"\n    [tuiHintAppearance]=\"computedAppearance\"\n    [tuiHintContext]=\"context\"\n    [tuiHintDescribe]=\"describeId\"\n    [tuiHintDirection]=\"direction\"\n    [tuiHintHideDelay]=\"hideDelay\"\n    [tuiHintShowDelay]=\"showDelay\"\n>\n    <tui-icon\n        *polymorpheusOutlet=\"icon as src\"\n        class=\"t-icon\"\n        [icon]=\"src\"\n    />\n</span>\n",
      styles: [":host{transition-property:color;transition-duration:var(--tui-duration, .3s);transition-timing-function:ease-in-out;position:relative;display:inline-block;inline-size:1.5rem;block-size:1.5rem;vertical-align:middle;font-size:0;line-height:0;pointer-events:auto;cursor:pointer;color:var(--tui-text-primary)}:host[data-appearance=error]{color:var(--tui-text-negative)}.t-tooltip-icon{display:inline-block;inline-size:100%;block-size:100%}.t-icon{border:.25rem solid transparent}\n"]
    }]
  }], null, {
    driver$: [{
      type: ViewChild,
      args: [TuiHintHover]
    }],
    describeId: [{
      type: Input
    }],
    context: [{
      type: Input
    }]
  });
})();

/**
 * @deprecated: drop in v5.0 use {@link TuiTooltip}
 * https://taiga-ui.dev/components/tooltip
 */
class TuiTooltipModule {
  static {
    this.ɵfac = function TuiTooltipModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiTooltipModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: TuiTooltipModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, TuiIcon, i1$1.TuiHintComponent]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiTooltipModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, TuiIcon, PolymorpheusOutlet, TuiAppearance, ...TuiHint],
      declarations: [TuiTooltipComponent],
      exports: [TuiTooltipComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiTooltipComponent, TuiTooltipModule };
